<template>
  <div class="absolute filter-wrapper">
    <div class="search flex flex-row items-center">
      <input
        :value="innerTitle"
        @input="onChangeTitle"
        @keyup.enter="onSubmitTitle"
        class="flex-1"
        placeholder="업체명 검색"
      />
      <button @click="onSubmitTitle" class="flex items-center justify-center">
        <img src="@/assets/search.png" />
      </button>
    </div>

    <div class="info flex flex-row items-center">
      <button>최근 검색어</button>
      <div class="flex flex-row items-center">
        <span class="recent" v-for="(item, index) in recents" :key="index">{{
          item
        }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import { mapState, mapActions } from "vuex";

export default {
  name: "InteriorFilter",
  props: {
    filter: Object,
    onChange: Function,
  },
  data() {
    return {
      innerTitle: "",
    };
  },
  computed: {
    ...mapState("interior", ["recents"]),
  },
  methods: {
    onChangeTitle(e) {
      this.innerTitle = e.target.value;
    },
    onSubmitTitle() {
      const recents = _.uniqBy(
        [this.innerTitle, ...this.recents],
        (x) => x
      ).slice(0, 3);
      this.setRecents(recents);
      this.onChange({ type: "title", value: this.innerTitle });
    },
    ...mapActions("interior", ["setRecents"]),
  },
};
</script>

<style scoped lang="scss">
.filter-wrapper {
  width: 425px;
  background-color: #fff;
  top: 27px;
  right: 43px;
  padding: 25px 25px 25px 25px;
  border: solid 1px #c9c9c9;
  transition: height 0.5s;

  .search {
    height: 38px;
    margin-bottom: 14px;

    input {
      height: 38px;
      border: 1px solid #bdbdbd;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: -0.7px;
      padding-left: 19px;
      padding-right: 19px;

      ::placeholder {
        color: #bdbdbd;
      }
    }

    button {
      width: 41px;
      height: 38px;
      background-color: #bdbdbd;
      border: 0;
    }
  }

  .info {
    button {
      height: 32px;

      font-size: 14px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: -0.7px;
      text-align: center;
      color: #bababa;
      border: 1px solid #e5e5e5;
      padding-left: 10px;
      padding-right: 10px;
      margin-right: 6px;

      img {
        margin-right: 6px;
      }

      &.active {
        color: #283aef;
        border: 1px solid #283aef;
        font-weight: normal;
      }
    }
  }

  hr {
    margin-top: 28px;
    margin-bottom: 26px;
  }

  .button-group {
    .label {
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: -0.7px;
      color: #7c7c7c;
      margin-bottom: 10px;
    }

    button {
      width: 112px;
      height: 34px;
      background-color: #fff;
      border: 1px solid #c1c1c1;

      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: -0.7px;
      text-align: center;
      color: #909090;

      margin-bottom: 7px;
      margin-right: 11px;

      &.active {
        background-color: #283aef;
        color: #fff;
      }
    }
  }

  .slide-group {
    margin-top: 35px;
    margin-bottom: 40px;

    .label {
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: -0.7px;
      color: #7c7c7c;
      margin-bottom: 10px;
    }

    .filter-dot {
      width: 14px;
      height: 14px;
      border-radius: 0;
      background-color: #283aef;
      transition: all 0.3s;
    }
  }

  .recent {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 3.43;
    letter-spacing: -0.7px;
    text-align: center;
    color: #bababa;
    margin-right: 9px;
    margin-left: 5px;
  }
}
</style>
