<template>
  <div class="interior-wrapper w-full h-full">
    <Jumbotron4 />

    <div class="w-full header flex flex-row items-center justify-center">
      <div
        class="container flex flex-row items-center justify-between relative"
      >
        <div class="header-tab flex flex-row">
          <router-link
            :class="[
              'flex',
              'items-center',
              'justify-center',
              { active: type === 'review' },
            ]"
            :to="{ name: 'InteriorReviewList' }"
            >사용자 후기</router-link
          >
          <router-link
            :class="[
              'flex',
              'items-center',
              'justify-center',
              { active: type === 'list' },
            ]"
            :to="{ name: 'InteriorList' }"
            >인테리어 업체</router-link
          >
        </div>

        <button
          @click="onVisible"
          v-if="hasSearch"
          class="flex justify-center items-center"
        >
          <img src="@/assets/search.png" />
        </button>

        <div
          v-if="type === 'review' && filterVisible"
          class="absolute filter-wrapper"
        >
          <InteriorReviewFilter
            :filter="reviewFilter"
            :onChange="onChangeReviewFilter"
          />
        </div>
        <div
          v-if="type === 'list' && filterVisible"
          class="absolute filter-wrapper"
        >
          <InteriorFilter
            :filter="interiorFilter"
            :onChange="onChangeInteriorFilter"
          />
        </div>
      </div>
    </div>

    <div class="w-full flex justify-center items-center">
      <div class="container interio-inner-wrapper">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
import Jumbotron4 from "@/components/Jumbotron4.vue";
import InteriorReviewFilter from "@/components/InteriorReviewFilter.vue";
import InteriorFilter from "@/components/InteriorFilter.vue";
import clickOutside from "@/directives/clickOutside";

export default {
  name: "InteriorLayout",
  components: { Jumbotron4, InteriorReviewFilter, InteriorFilter },
  directives: {
    clickOutside,
  },
  props: {
    type: String,
    hasSearch: {
      type: Boolean,
      default: true,
    },
    onReviewFilter: {
      type: Function,
      default: () => {},
    },
    onInteriorFilter: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      filterVisible: false,
      reviewFilter: {},
      interiorFilter: {},
    };
  },
  methods: {
    onVisible() {
      this.filterVisible = !this.filterVisible;
    },
    onChangeReviewFilter({ type, value }) {
      switch (type) {
        case "contents":
          this.reviewFilter.office_title = value;
          break;
        case "minRating":
          this.reviewFilter.minRating = value;
          break;
        case "maxRating":
          this.reviewFilter.maxRating = value;
          break;
        default:
          break;
      }
      this.onReviewFilter(this.reviewFilter);
    },
    onChangeInteriorFilter({ type, value }) {
      switch (type) {
        case "title":
          this.interiorFilter.title = value;
          break;
        default:
          break;
      }
      this.onInteriorFilter(this.interiorFilter);
    },
  },
};
</script>

<style scoped lang="scss">
.interior-wrapper {
  margin-top: 100px;

  .header {
    height: 60px;
    border-bottom: 1px solid rgba(193, 193, 193, 0.2);

    .header-tab {
      height: 60px;

      a {
        height: 60px;
        font-size: 18px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: -0.9px;
        text-align: left;
        color: #bfbfbf;
        margin-right: 42px;

        &.active {
          font-size: 18px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          letter-spacing: -0.9px;
          text-align: left;
          color: #293bf0;
          border-bottom: 2px solid #293bf0;
        }
      }
    }

    button {
      background-color: #283aef;
      width: 62px;
      height: 60px;

      img {
        width: 27px;
        height: 27px;
      }
    }
  }
}

.interior-wrapper {
  min-height: 350px;
}

.filter-wrapper {
  top: 30px;
  right: 0;
  z-index: 10;
}

@media (max-width: 1023px) {
  .interior-wrapper {
    margin-top: 56px;
  }
}

@media (max-width: 639px) {
  .interior-wrapper {
    .header {
      height: 40px;
      .header-tab {
        height: 40px;
        a {
          height: 40px;
          font-size: 12px;
          &.active {
            font-size: 12px;
          }
        }
      }
      button {
        width: 45px;
        height: 40px;
        img {
          width: 18px;
          height: 18px;
        }
      }
    }
  }
  .filter-wrapper {
    top: 20px;
    width: 100%;
    padding: 10px 15px;
  }
}
</style>
