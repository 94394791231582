var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"interior-wrapper w-full h-full"},[_c('Jumbotron4'),_c('div',{staticClass:"w-full header flex flex-row items-center justify-center"},[_c('div',{staticClass:"container flex flex-row items-center justify-between relative"},[_c('div',{staticClass:"header-tab flex flex-row"},[_c('router-link',{class:[
            'flex',
            'items-center',
            'justify-center',
            { active: _vm.type === 'review' } ],attrs:{"to":{ name: 'InteriorReviewList' }}},[_vm._v("사용자 후기")]),_c('router-link',{class:[
            'flex',
            'items-center',
            'justify-center',
            { active: _vm.type === 'list' } ],attrs:{"to":{ name: 'InteriorList' }}},[_vm._v("인테리어 업체")])],1),(_vm.hasSearch)?_c('button',{staticClass:"flex justify-center items-center",on:{"click":_vm.onVisible}},[_c('img',{attrs:{"src":require("@/assets/search.png")}})]):_vm._e(),(_vm.type === 'review' && _vm.filterVisible)?_c('div',{staticClass:"absolute filter-wrapper"},[_c('InteriorReviewFilter',{attrs:{"filter":_vm.reviewFilter,"onChange":_vm.onChangeReviewFilter}})],1):_vm._e(),(_vm.type === 'list' && _vm.filterVisible)?_c('div',{staticClass:"absolute filter-wrapper"},[_c('InteriorFilter',{attrs:{"filter":_vm.interiorFilter,"onChange":_vm.onChangeInteriorFilter}})],1):_vm._e()])]),_c('div',{staticClass:"w-full flex justify-center items-center"},[_c('div',{staticClass:"container interio-inner-wrapper"},[_vm._t("default")],2)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }