<template>
  <div class="absolute filter-wrapper">
    <div class="search flex flex-row items-center">
      <input
        :value="innerContent"
        @input="onChangeContent"
        @keyup.enter="onSubmitContent"
        class="flex-1"
        placeholder="업체명 검색"
      />
      <button @click="onSubmitContent" class="flex items-center justify-center">
        <img src="@/assets/search.png" />
      </button>
    </div>

    <div class="info flex flex-row items-center">
      <button
        class="flex flex-row items-center"
        @click="onCollapse"
        :class="{ active: !isCollapse }"
      >
        <img v-if="!isCollapse" src="@/assets/filter.png" />
        검색조건
      </button>
    </div>

    <hr v-if="!isCollapse" />

    <div v-if="!isCollapse" class="slide-group">
      <p class="label">별점</p>

      <vue-slider
        v-model="rating"
        direction="ltr"
        :marks="marks"
        :width="'100%'"
        :min="0"
        :max="5"
        :interval="1"
        :process="true"
        :process-style="{ backgroundColor: '#283aef' }"
        tooltip="none"
      >
        <template v-slot:dot="{ focus }">
          <div :class="['filter-dot', { focus }]"></div>
        </template>
      </vue-slider>
    </div>
  </div>
</template>

<script>
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/default.css";
import _ from "lodash";

export default {
  name: "InteriorReviewFilter",
  components: {
    VueSlider,
  },
  props: {
    filter: Object,
    onChange: Function,
  },
  data() {
    return {
      innerContent: "",
      isCollapse: true,
      rating: [0, 5],
      marks: (val) => ({
        label: `${val === 0 ? "최소" : ""}${val === 5 ? "최대" : ""}${
          val !== 0 && val !== 5 ? `${val}점` : ""
        }`,
        labelStyle: {
          fontSize: "10px",
          fontWeight: "normal",
          fontStretch: "normal",
          fontStyle: "normal",
          letterSpacing: "-0.5px",
          textAlign: "center",
          color: "#afafaf",
        },
      }),
    };
  },
  methods: {
    onCollapse() {
      this.isCollapse = !this.isCollapse;
    },
    onChangeContent(e) {
      this.innerContent = e.target.value;
    },
    onSubmitContent() {
      this.onChange({ type: "contents", value: this.innerContent });
    },
  },
  watch: {
    rating: _.debounce(function(newValue) {
      if (newValue[0] !== 0) {
        this.onChange({ type: "minRating", value: newValue[0] });
      }
      if (newValue[1] !== 1000000000) {
        this.onChange({ type: "maxRating", value: newValue[1] });
      }
    }, 300),
    content(newValue) {
      if (newValue !== this.innerContent) {
        this.innerContent = newValue;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.filter-wrapper {
  width: 425px;
  background-color: #fff;
  top: 27px;
  right: 43px;
  padding: 25px;
  border: solid 1px #c9c9c9;
  transition: height 0.5s;

  .search {
    height: 38px;
    margin-bottom: 14px;

    input {
      height: 38px;
      border: 1px solid #bdbdbd;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: -0.7px;
      padding-left: 19px;
      padding-right: 19px;

      ::placeholder {
        color: #bdbdbd;
      }
    }

    button {
      width: 41px;
      height: 38px;
      background-color: #bdbdbd;
      border: 0;
    }
  }

  .info {
    button {
      height: 32px;

      font-size: 14px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: -0.7px;
      text-align: center;
      color: #bababa;
      border: 1px solid #e5e5e5;
      padding-left: 10px;
      padding-right: 10px;
      margin-right: 6px;

      img {
        margin-right: 6px;
      }

      &.active {
        color: #283aef;
        border: 1px solid #283aef;
        font-weight: normal;
      }
    }
  }

  hr {
    margin-top: 28px;
    margin-bottom: 26px;
  }

  .button-group {
    .label {
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: -0.7px;
      color: #7c7c7c;
      margin-bottom: 10px;
    }

    button {
      width: 112px;
      height: 34px;
      background-color: #fff;
      border: 1px solid #c1c1c1;

      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: -0.7px;
      text-align: center;
      color: #909090;

      margin-bottom: 7px;
      margin-right: 11px;

      &.active {
        background-color: #283aef;
        color: #fff;
      }
    }
  }

  .slide-group {
    margin-top: 35px;
    margin-bottom: 40px;

    .label {
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: -0.7px;
      color: #7c7c7c;
      margin-bottom: 10px;
    }

    .filter-dot {
      width: 14px;
      height: 14px;
      border-radius: 0;
      background-color: #283aef;
      transition: all 0.3s;
    }
  }

  .recent {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 3.43;
    letter-spacing: -0.7px;
    text-align: center;
    color: #bababa;
    margin-right: 9px;
    margin-left: 5px;
  }
}

@media (max-width: 639px) {
  .filter-wrapper {
    .search {
      height: 30px;
      margin-bottom: 8px;
      input {
        font-size: 11px;
        height: 30px;
        line-height: 1;
      }
      button {
        width: 30px;
        height: 30px;
        img {
          width: 18px;
          height: 18px;
        }
      }
    }
    .info {
      button {
        height: 23px;
        font-size: 10px;
        line-height: 1;
      }
    }
    hr {
      margin: 15px 0;
    }
    .slide-group {
      margin: 20px 0 30px;
      .label {
        font-size: 11px;
      }
      .filter-dot {
        width: 10px;
        height: 10px;
        margin-top: 2px;
      }
      .vue-slider {
        width: 90% !important;
        margin: 0 auto;
      }
    }
  }
}
</style>
